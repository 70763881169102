<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent,
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div></div>
  </md-toolbar>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  data() {
    return {};
  },
};
</script>
