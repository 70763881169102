<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">Enciclopedia Literaturii Romane Vechi</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="row text-center d-flex justify-content-center">
              <button
                v-for="letter in letters"
                :key="letter"
                class="col-1 md-button md-round md-theme-default p-2 mx-1"
                :class="{
                  'md-success': letter == selectedLetter,
                  'md-white': letter != selectedLetter,
                }"
                @click="onClickLetter(letter)"
              >
                {{ letter }}
              </button>
            </div>
          </div>
        </div>
        <div class="me-5">
          <div class="md-layout m-4" v-if="!showProgress">
            <div class="col-2 mx-auto">
              <p v-for="item in eliv" :key="item.name"
                class="author-name"
                :class="{
                  'clickable-author-name-selected': item.name == selectedAuthorName,
                  'clickable-author-name': item.name != selectedAuthorName}"
                  @click="onClickAuthorName(item)">{{ item.name }}
              </p>
            </div>
            <div class="col mx-5">
                <h4>{{ selectedAuthor.name }}</h4>
                <p
                  v-for="(par, pIndex) in selectedAuthor.text"
                  :key="`par-${pIndex}`"
                  v-html="par"
                ></p>
                <div v-if="selectedAuthor.writings">
                  <h6><u>Scrieri</u></h6>
                  <p
                    v-for="(writ, wIndex) in selectedAuthor.writings"
                    :key="`wrt-${wIndex}`"
                    v-html="writ"
                  ></p>
                </div>
                <div v-if="selectedAuthor.biblio">
                <h6><u>Bibliografie</u></h6>
                  <p
                    v-for="(bib, bIndex) in selectedAuthor.biblio"
                    :key="`bib-${bIndex}`"
                    v-html="bib"
                  ></p>
                </div>
            </div>
            <div v-if="selectedAuthor.images && selectedAuthor.images.length > 0" class="col-2">
              <p 
                v-for="(image, iIndex) in selectedAuthor.images" 
                :key="`img-${iIndex}`"
                v-html="image"></p>
            </div>
            <!-- <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageSize"
              aria-controls="my-table"
            ></b-pagination> -->
          </div>
          <div class="middle" v-else>
            <vue-ellipse-progress
              loading
              :size="50"
              :progress="100"
              color="green"
              emptyColor="#a4acb3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Tabs } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    // Tabs,
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/examples/bg-pricing.jpg"),
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "Ș",
        "T",
        "Ț",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      selectedLetter: "A",
      eliv: [],
      selectedAuthor: {},
      selectedAuthorName: "",
      showProgress: true,
      // currentPage: 1,
      // pageSize: 5,
      // totalItems: 0,
    };
  },
  methods: {
    async onClickLetter(letter) {
      this.eliv = [];
      this.showProgress = true;
      this.selectedLetter = letter;
      // production
      this.axios.get(`${process.env.VUE_APP_SERVER_URL}/eliv/` + letter).then(
        response => {
          if (response && response.status == 200) {
            this.eliv = response.data;
            // this.totalItems = response.data.count;
            this.showProgress = false;
            this.selectedAuthor = response.data[0];
            if (this.selectedAuthor) {
              this.selectedAuthorName = this.selectedAuthor.name;
            }
          }
        }
      );  
    },
    onClickAuthorName(author) {
      this.selectedAuthor = author;
      this.selectedAuthorName = this.selectedAuthor.name;
    }
  },
  async mounted() {
    this.onClickLetter("A");
  },
};
</script>

<style lang="scss" scoped>
img {
  max-height: 20rem;
}

.clickable-author-name {
  text-decoration: underline;
  color: #bfc1c3;
}

.clickable-author-name-selected {
  font-weight: 700;
  color: #f94125;
}

.author-name {
  cursor: pointer;
  text-align: right;
}

.middle {
  margin: 0% 50%;
}
</style>
