<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1>Vue Material Kit</h1>
              <span class="pro-badge"> Pro </span>
              <h3 class="title">
                Start the Development With A Badass Vuematerial.io UI Kit
                inspired by Material Design.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <!-- Introduction section -->
      <introduction />

      <!-- Components section -->
      <components />

      <!-- Cards section -->
      <cards />

      <!-- Content Areas section -->
      <content-areas />

      <!-- Sections section -->
      <sections />

      <!-- Examples sections  -->
      <example-pages />

      <!-- Github sections  -->
      <github />

      <!-- Overview sections -->
      <overview />
    </div>

    <!-- Pricing Section -->
    <pricing />
  </div>
</template>

<script>
import Introduction from "./presentation/Introduction.vue";
import Sections from "./presentation/Sections.vue";
import Cards from "./presentation/Cards.vue";
import ExamplePages from "./presentation/Examples.vue";
import Github from "./presentation/Github.vue";
import Overview from "./presentation/Overview.vue";
import Pricing from "./presentation/Pricing.vue";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    Introduction,
    Cards,
    Sections,
    ExamplePages,
    Github,
    Overview,
    Pricing,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "presentation-page",
  props: {
  },
  data() {
    return {
      image: require("@/assets/img/vue-mk-header.jpg"),
    };
  },
  mounted() {
    
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    
  },
};
</script>

<style lang="scss"></style>
