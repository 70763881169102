<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter header-medium"
parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">Cronologia Literaturii Române Vechi</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div>
          <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
              <div class="d-flex justify-content-end">
                <img :src="ro" alt="" class="flag me-1 cursor-pointer" @click="onClickLanguage(`ro`)">
                <img :src="en" alt="" class="flag me-5 cursor-pointer" @click="onClickLanguage(`en`)">
              </div>
              <div class="row dc-timeline m-0 mx-5">
                <nav class="dc-timeline__nav col-3">
                  <ul>
                    <li
                      v-for="item in periods"
                      :key="item"
                      @click="onClickPeriod(item)"
                      :class="{ 'dc-active': item == selectedItem }"
                    >
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                </nav>
                <div class="col me-4 dc-timeline__section">
                  <div :options="{supressScrollX: true}" class="dc-wrapper">
                    <div class="row">
                      <div class="col">
                        <div v-for="elem in dclr[selectedItem]" :key="elem.id">
                          <h2 class="dc-milestone" v-html="elem.title"></h2>
                          <div>
                            <p
                              class="text-justify me-5"
                              v-for="(text, index) in elem.text"
                              :key="index"
                              v-html="getText(text)"
                            ></p>
                          </div>
                        </div>
                      </div>

                      <div v-if="dclrImages[selectedItem] && dclrImages[selectedItem].length > 0" class="col-3 d-flex flex-column justify-content-between">
                        <div
                          v-for="(elem, index) in dclrImages[selectedItem]"
                          :key="index"
                        >
                          <img :ref="elem" :src="getFullImgSrc(elem)" alt="" @click="onClickImage(`modal-${elem}`)" class="cursor-pointer mb-3"/>
                          <!-- modal window for images -->
                          <b-modal
                            :ref="`modal-${elem}`"
                            hide-footer
                            hide-header
                          >
                            <img :src="getFullImgSrc(elem)" alt="" />
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

import "@/assets/scss/cronologie.scss";

export default {
  components: {},
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/bg-intelliv.png"),
      ro: require("@/assets/img/svg/flags/ro.svg"),
      en: require("@/assets/img/svg/flags/uk.svg"),
      dclr: {},
      items: [],
      periods: [],
      dclrImages: {},
      selectedItem: "",
      lang: "ro"
    };
  },
  methods: {
    async onClickLanguage(language) {
    if (this.lang !== language) {
      this.lang = language;
      // this.getDclrImages();
      this.getDclr();
    }
  },
    getText(text) {
      let response = "";
      if (text.hasOwnProperty("paragraph")) {
        response += text.paragraph.join(" ");
      }
      if (text.hasOwnProperty("line")) {
        response += text.line.join(" ");
      }
      return response;
    },
    getFullImgSrc(src) {
      return require(`@/assets/img/cronologie/${src}`);
    },
    onClickPeriod(item) {
      this.selectedItem = item;
    },
    onClickImage(elemId) {
      this.$refs[elemId][0].show();
    },
    async getDclr() {
      this.periods = [];
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dclr?lang=${this.lang}`); // production
      if (response && response.status == 200) {
        this.dclr = response.data;

        for (let item in this.dclr) {
          this.periods.push(item);
        }

        this.selectedItem = this.periods[0];
      }
    },
    async getDclrImages() {
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dclr/images?lang=${this.lang}`); // production
      if (response && response.status == 200) {
        this.dclrImages = response.data;
      }
    },
  },
  async mounted() {
    this.getDclrImages();
    this.getDclr();
  },
};
</script>

<style lang="scss" scoped>
// .title {
//   font-family: "MedievalSharp" !important;
// }

.flag {
  height: 16px;
}

.ps {
  height: 50vh;
}

.ps__thumb-x {
  visibility: hidden;
}

.dc-wrapper {
  width: 100%;

}

.image-small {
  max-width: 15vw;
}

.cursor-pointer {
  cursor: pointer;
}

.dc-timeline {
  &__nav {
    z-index: 99;
    transition: top 0.3s ease-out;

    ul {
      list-style: none;
      list-style-position: inside;
      margin: 15px 0;
      padding: 0;

      li {
        margin: 15px 0;
        padding-left: 0;
        list-style-type: none;
        color: #bfc1c3;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &.dc-active {
          font-weight: bold;
          color: #f94125;
          transform: scale(1.2);
        }

        &:hover {
          color: #000;
        }
      }
    }
  }
}
</style>
