<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter header-medium" parallax-active="true" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Texte Fundamentale ale Literaturii Române Vechi
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div>
          <div class="md-layout">
            <div class="md-layout-item mx-auto text-center">
              <template>
                <div class="row">
                  <div class="col-3">
                    <button class="btn btn-block pdf-entry w-100 ms-4 me-2 mb-2 btn-light" v-for="item in pdfList"
                      :key="item" @click="selectPdf(item)">
                      {{ item }}
                    </button>
                  </div>
                  <div class="col me-4 ms-2">
                    <object class="full-height" :data="selectedPdfData" type="application/pdf" width="100%" height="100%">
                    </object>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      image: require("@/assets/img/bg-intelliv.png"),
      pdfList: [],
      selectedPdf: "",
      selectedPdfData: "",
    };
  },
  methods: {
    selectPdf(item) {
      this.selectedPdf = item;
      this.getPdfData();
    },
    async getPdfNames() {
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/tvlr`);
      if (response && response.status == 200) {
        this.pdfList = response.data;
        this.selectPdf(this.pdfList[0]);
      }
    },
    async getPdfData() {
      let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/tvlr/${this.selectedPdf}`, { responseType: 'blob' });
      if (response && response.status == 200) {
        let blob = new Blob([response.data], {type: 'application/pdf'});
        let url = URL.createObjectURL(blob);
        this.selectedPdfData = url;
      }
    }
  },
  async mounted() {
    this.getPdfNames();
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.pdf-entry {
  font-size: 12px;
}

.full-height {
  height: 100vh;
}
</style>
