// =========================================================
// * Vue Material Kit PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import "bootstrap";

import axios from 'axios';
import VueAxios from 'vue-axios';

import i18n from "@/core/services/i18n.service.js";

import MaterialKit from "./plugins/material-kit";

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueEllipseProgress from 'vue-ellipse-progress';

Vue.config.productionTip = false;

Vue.use(MaterialKit);
Vue.use(VueAxios, axios);
Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueEllipseProgress);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
