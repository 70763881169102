<template>
  <md-toolbar
    class="color-toolbar md-fixed"
    id="toolbar"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="#/"><h3 class="md-title title">
          {{ brand }}
        </h3></a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <li
                class="md-list-item"
                v-for="section in sections"
                :key="section.name"
              >
                <a
                  :href="'#/' + section.href"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <md-button
                      slot="title"
                      class="md-button md-button-link md-white md-simple"
                    >
                      <p>{{ section.name }}</p>
                    </md-button>
                    <md-tooltip md-direction="bottom">{{ section.tooltip }}</md-tooltip>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark",
        ].includes(value);
      },
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
    navbarTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      brand: "INTELLIV",
      sections: [
        {
          name: "ELIV",
          tooltip: "Enciclopedia Literaturii Române Vechi",
          href: "enciclopedie",
          icon: "line_style",
          subsections: [{ name: "", href: "", icon: "" }],
        },
        {
          name: "CLVR",
          tooltip: "Cronologia Literaturii Române Vechi",
          href: "cronologia",
          icon: "layers",
          subsections: [{ name: "", href: "", icon: "" }],
        },
        {
          name: "HLVR",
          tooltip: "Cartografia Literaturii Române Vechi",
          href: "harta",
          icon: "location_on",
          subsections: [{ name: "", href: "", icon: "" }],
        },
        {
          name: "TLVR",
          tooltip: "Texte Fundamentale ale Literaturii Române Vechi",
          href: "antologie",
          icon: "view_carousel",
          subsections: [{ name: "", href: "", icon: "" }],
        },
      ],
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["index"];
      return excludedRoutes.every((r) => r !== this.$route.name);
    },
  },
  mounted() {
    // document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    // document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    // handleScroll() {
    //   let scrollValue =
    //     document.body.scrollTop || document.documentElement.scrollTop;
    //   let navbarColor = document.getElementById("toolbar");
    //   this.currentScrollValue = scrollValue;
    //   if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
    //     this.extraNavClasses = `md-${this.type}`;
    //     navbarColor.classList.remove("md-transparent");
    //   } else {
    //     if (this.extraNavClasses) {
    //       this.extraNavClasses = "";
    //       navbarColor.classList.add("md-transparent");
    //     }
    //   }
    // },
    // scrollListener() {
    //   resizeThrottler(this.handleScroll);
    // },
  },
};
</script>
<style>
  .md-tooltip {
    max-width: 100% !important;
  }

  .md-toolbar {
    align-items: center !important;
  }

  .md-list-item a .md-ripple p {
    font-size: 1.2em !important;
  }

  .md-toolbar.md-theme-default {
    background-color: #669267 !important;
  }
</style>
