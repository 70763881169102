<template>
  <footer
    class="footer container"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size,
    }"
    :data-background-color="backgroundColor"
  >
    <template>
      <div class="w-100 pt-4 row d-flex justify-content-center">
        <div>
          <a
            href="https://acad.ro/institutia/institute.html?2#__"
            target="_blank"
            >Institutul de Istorie și Teorie Literară „G. Călinescu“, Academia Română</a
          >
        </div>
        <div>&copy; {{ year }}</div>
        <div>
          <img class="logo-img mr-5" src="/acad-logo.png" alt="" />
          <img class="logo-img" src="/uefiscdi-logo.svg" alt="" />
        </div>
        <p style="color: #1b2d56" class="text-center">
          Proiect „Soluții digitale inteligente pentru cercetarea și diseminarea
          literaturii române vechi și premoderne”, acronim INTELLIV,
          PN-III-P4-PCE-2021-0300
        </p>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
.md-theme-default a:not(.md-button) {
  color: #1b2d56 !important;
}

.logo-img {
  height: 100px !important;
}
</style>
