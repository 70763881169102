<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter header-medium"
      parallax-active="true"
      :style="headerStyle"
    >
      <!-- <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100">
            <h1 class="title">INTELLIV</h1>
            <br />
          </div>
        </div>
      </div> -->
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <h2 class="title text-center">Descrierea proiectului</h2>
              <p class="description desc-text">
                Obiectivul general al proiectului constă în realizarea unei platforme digitale inteligente INTELLIV care să permită accesul la colecții de referință academică plasate în perimetrul de cercetare al literaturii române vechi și premoderne.
                Astfel, platforma software integrată INTELLIV devine suport pentru implementarea digitală a corpusurilor ELIV, CLRV, HLRV, TLVR,  și prin aceasta  contribuie la diseminarea de  cunoștințe fundamentale de identitate culturală și națională, pertinente racordării la sistemul radicular al literaturilor europene.   
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section text-center pb-5">
        <div class="container">
          <h2 class="title">Axe principale</h2>
          <div class="team">
            <div class="md-layout">
              <div
                class="md-layout-item md-size-50 md-small-size-100"
                v-for="project in projects"
                :key="project.name"
              >
                <profile-card
                  text-center
                  card-plain
                  type="horizontal"
                  :shadow-normal="false"
                  :no-colored-shadow="false"
                  :card-image="project.image"
                  :imageLink="project.href"
                >
                  <template slot="cardContent">
                    <h4 class="card-title">
                      <a :href="project.href">{{ project.name }}</a>
                    </h4>
                    <h6 class="card-category text-muted">
                      {{ project.shortName }}
                    </h6>
                    <p class="card-description">
                      {{ project.description }}
                    </p>
                  </template>
                </profile-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProfileCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "landing-page",
  data() {
    return {
      image: require("@/assets/img/bg-intelliv.png"),
      projects: [
        {
          name: "Enciclopedia Literaturii Române Vechi",
          shortName: "ELIV",
          href: "#/enciclopedie",
          image: require("@/assets/img/covers/enciclopedia.png"),
          description:
            "Bază de date ce cuprinde aproximativ 500 de articole de profil cu referire la momentele semnificative ale literaturii noastre de la primele texte redactate în perimetrul românesc până la 1830.",
        },
        {
          name: "Cronologia Literaturii Române Vechi",
          shortName: "CLVR",
          href: "#/cronologia",
          image: require("@/assets/img/covers/dictionar.png"),
          description:
            "Bază de date ce conține ordonarea cronologică (exhaustivă) a vechilor noastre scrieri: manuscrise și tipărituri realizate în spațiul românesc (sau cu strictă referire la el), redactate în limba română și în principalele limbi de cultură: slavonă, latină, greacă etc.",
        },
        {
          name: "Cartografia Literaturii Române vechi",
          shortName: "HLRV",
          href: "#/harta",
          image: require("@/assets/img/covers/dictionar.jpg"),
          description:
            "Manuscrise (I) și tipărituri (II). Avem în vedere realizarea unei hărți interactive care să ilustreze circulația scrierilor românești (manuscrise și tipărituri) în lume, prin consemnarea bibilotecii sau colecției în care se află astăzi.",
        },
        {
          name: "Texte Fundamentale ale Literaturii Române Vechi",
          href: "#/antologie",
          shortName: "TLRV",
          image: require("@/assets/img/covers/dictionar.jpg"),
          description:
            "Bază de date ce cuprinde aproximativ 25 de opere de referință ale literaturii noastre vechi, în ediție critică, astfel încât să fie ilustrate toate stilurile funcționale limbii române literare din perioada de început până la 1830.",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}

.desc-text {
  font-size: 1.2rem;
  font-weight: 400;
}
</style>
