import Vue from "vue";
import Router from "vue-router";

// INTELLIV

import LandingPage from "./views/LandingPage.vue";
import Antologia from "./views/presentation/Antologia.vue";
import CronologiaLiteraturii from "./views/presentation/CronologiaLiteraturii.vue";
import EnciclopediaLiteraturii from "./views/presentation/EnciclopediaLiteraturii.vue";
import Harta from "./views/presentation/Harta.vue";

//  Main Pages
import Index from "./views/Index.vue";
import Sections from "./views/Sections.vue";
import Presentation from "./views/Presentation.vue";

//  Main Examples
import Landing from "./views/examples/Landing.vue";
import Login from "./views/examples/Login.vue";
import Profile from "./views/examples/Profile.vue";
import AboutUs from "./views/examples/AboutUs.vue";
import BlogPost from "./views/examples/BlogPost.vue";
import BlogPosts from "./views/examples/BlogPosts.vue";
import ContactUs from "./views/examples/ContactUs.vue";
import Pricing from "./views/examples/Pricing.vue";
import Signup from "./views/examples/Signup.vue";
import Error from "./views/examples/Error.vue";

import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

Vue.use(Router);

export default new Router({
  routes: [
    // Landing page
    {
      path: "/",
      name: "landing",
      components: {
        default: LandingPage,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Enciclopedia
    {
      path: "/enciclopedie",
      name: "enciclopedie",
      components: {
        default: EnciclopediaLiteraturii,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Cronologia
    {
      path: "/cronologia",
      name: "cronologia",
      components: {
        default: CronologiaLiteraturii,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Antologia
    {
      path: "/antologie",
      name: "antologie",
      components: {
        default: Antologia,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // Harta
    {
      path: "/harta",
      name: "harta",
      components: {
        default: Harta,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, navbarTransparent: true },
      },
    },
    // {
    //   path: "/about-us",
    //   name: "about-us",
    //   components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
    //   props: {
    //     header: { colorOnScroll: 400, navbarTransparent: true },
    //   },
    // },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
